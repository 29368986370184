import React, { useContext, useState, useEffect } from "react";
import { Button } from "@3beehivetech/components";
import {
  StyledNewsletterForm, StyledWrapperInputs, StyledTextArea, StyledNumberCharacters, StyledTextInput, StyledWrapperForm,
} from "./style";
import useNewsletter from "../../../hooks/useNewsletter";
import { toast } from "react-toastify";
import { DataContext } from "../../../contexts/DataContext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

const ValentineNewsletter = () => {
  toast.configure();
  const { pageLabels, languages } = useContext(DataContext);
  const labels = pageLabels.newsletter;
  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";
  const { addToNewsLetter } = useNewsletter();

  const [messageLength, setMessageLength] = useState({
    min: 0,
    max: 100,
  });

  const formSchema = z.object({
    email: z.string().email().min(1),
    message: z.string().min(1).max(100),
  });

  const {
    handleSubmit,
    formState: {
      errors,
    },
    register,
    setFocus,
    reset,
  } = useForm({ resolver: zodResolver(formSchema) });

  const handleNewsletterSubmit = async (data) => {
    const response = await addToNewsLetter({
      data: {
        email: data.email,
        lang: currentLanguageCode,
        list_id: 159,
        attributes: {
          SAN_VALENTINO_MESSAGGIO: data.message,
        },
      },
    });

    if (response.status === 200) {
      toast.success(labels.success, {
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    } else {
      toast.error(labels.error, {
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER,
      });
    }
    reset();
  };

  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const fieldName = keys[0];
      const name = errors[fieldName].ref?.placeholder ?? fieldName;
      setFocus(fieldName);
      toast.error(`${name}: ${errors[fieldName].message}`,{
        containerId: "Alert",
        position: toast.POSITION.BOTTOM_CENTER, 
      });
    }
  }, [setFocus, errors]);

  return (
    <StyledWrapperForm>
      <StyledNewsletterForm
        Validate
        onSubmit={
          handleSubmit(handleNewsletterSubmit)
        }
      >
        <StyledWrapperInputs>
          <StyledTextInput
            type="email"
            name="email"
            required
            placeholder={labels.placeholderEmail}
            {...register("email")}
          />
          <StyledTextArea
            type="text"
            name="text"
            id="contacts-request"
            required
            placeholder={labels.placeholderMessage}
            onInput={(e) =>
              setMessageLength({
                ...messageLength,
                min: e.currentTarget.value.replace(/\s+/g, "").length, 
              })
            }
            {...register("message")}
          />
          <StyledNumberCharacters>{messageLength.min.toString()} / {messageLength.max.toString()}</StyledNumberCharacters>
        </StyledWrapperInputs>
        <Button style="padding: 13px 70px;">
          {labels.button}
        </Button>
      </StyledNewsletterForm>
    </StyledWrapperForm>
  );
};

export default ValentineNewsletter;
