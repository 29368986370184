import React, { useContext } from "react";
import Faq from "../../components/Faq/Faq";
import Intro from "../../components/Intro_v2/Intro_v2";
import Meta from "../../components/Meta/Meta";
import Spacer from "../../components/Spacer/Spacer";
import SectionTwoCol from "../../components/SectionTwoCol/SectionTwoCol";
import { DataContext } from "../../contexts/DataContext";
import { toast } from "react-toastify";
import KPI from "../../components/KPI/KPI";
import useTrack from "../../hooks/useTrack";
import useSnipcartEvents from "../../hooks/useSnipcartEvents";
import Hero3BC from "../../components/Hero3BC/Hero3BC";
import BusinessIconText from "../../components/BusinessIconText/BusinessIconText";
import BusinessCards from "../../components/BusinessCards/BusinessCards";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import CardsValentine from "../../components/CardsValentine/CardsValentine";
import ValentineNewsletter from "../../components/CardsValentine/components/ValentineNewsletter";

const SanValentino = () => {
  const { 
    pageLabels,
    currentLanguageSuffix,
    pageImages,
  } = useContext(DataContext);
  const { trackCustomEvent } = useTrack();
  toast.configure();
  useSnipcartEvents();
  const isMobile = useCheckMobileScreen();
  return (
    <>
      <Meta />
      <Hero3BC
        title = {pageLabels.hero.title}
        image = {pageLabels.hero.image}
        paragraph = {pageLabels.hero.paragraph}
        buttonText = {pageLabels.hero.cta}
        to = "#businessCard"
        titleBackground = "transparent"
        buttonVariant="orange"
        isValentineSection
        noNewsletter={true}
        lang={currentLanguageSuffix}
        allImages = {pageImages}
      />
      <Spacer size={10}/>
      <SectionTwoCol
        id="badgift"
        title={pageLabels.badgift.title}
        subtitle1={pageLabels.badgift.paragraph}
        subtitle2={pageLabels.badgift.paragraph2}
        image={pageLabels.badgift.image}
        isValentineSection
      />

      <Spacer size={15}/>

      <Intro
        bigText={pageLabels.love.title}
        text={pageLabels.love.text}
        textAlign="center"
        isValentine
        isLove
      />
      <ValentineNewsletter />

      <Spacer size={15}/>

      <Intro
        bigText={isMobile ? pageLabels.payCards.titleMbl : pageLabels.payCards.title}
        textAlign="center"
        isValentine
      />
      <CardsValentine />

      <Spacer size={15}/>
      
      <Intro
        bigText={pageLabels.bees.title}
        subtitle={pageLabels.bees.subtitle}
        textAlign="center"
        isValentine
      />
      <BusinessIconText
        items={[{
          image: "/san-valentino/planet.png",
          label: pageLabels.bees.impollination.label,
          text: pageLabels.bees.impollination.text,
        },{
          image: "/san-valentino/API.png",
          label: pageLabels.bees.ecosystem.label,
          text: pageLabels.bees.ecosystem.text,
        },{
          image: "/san-valentino/gift.png",
          label: pageLabels.bees.value.label,
          text: pageLabels.bees.value.text,
        },{
          image: "/san-valentino/education.png",
          label: pageLabels.bees.bioindicators.label,
          text: pageLabels.bees.bioindicators.text,
        }]}
      />

      <BusinessCards cards = {pageLabels.cards} isValentinePage />

      <KPI/>

      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.hive.id}
        title={pageLabels.hive.title}
        subtitle1={pageLabels.hive.paragraph}
        cta={pageLabels.hive.button}
        cta2={pageLabels.hive.buttonTwo}
        to="/regala-un-alveare"
        to2="/box/adottatori/"
        images={["/san-valentino/alveare.png","/san-valentino/box.png"]}
        rightImage
        isValentineSection
        valentineDoubleCta
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un alveare",
          });
        }}
      />

      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.tree.id}
        title={pageLabels.tree.title}
        subtitle1={pageLabels.tree.paragraph}
        cta={pageLabels.tree.button}
        to="/adotta-un-albero"
        image="/san-valentino/albero.png"
        isValentineSection
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un albero",
          });
        }}
      />
      
      <Spacer size={10}/>

      <SectionTwoCol
        id={pageLabels.bee.id}
        title={pageLabels.bee.title}
        subtitle1={pageLabels.bee.paragraph}
        cta={pageLabels.bee.button}
        to="/adotta-un-ape"
        isValentineSection
        onClickCTA={() => {
          trackCustomEvent({
            category: "Service",
            action: "click",
            label: "Adotta un'ape",
          });
        }}
        image="/san-valentino/ape.png"
        rightImage
      />

      <Spacer size={10}/>

      <Faq
        data={pageLabels.faq.data}
        isValentine
      />
    </>
  );
};

export default SanValentino;
