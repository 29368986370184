import React, { useEffect, useState, useContext } from "react";

import useCounter from "../../hooks/useCounter";
import CausesList from "../CausesList/CausesList";
import { DataContext } from "../../contexts/DataContext";
import { StyledContainer } from "../globalstyle";
import useApi from "../../hooks/useApi";

export default ({ showBoxKpi = false }) => {

  const { labels } = useContext(DataContext);
  const counter = useCounter(2 * 60 * 30, 30 * 60 * 1000);
  const [KPI, setKPI] = useState({
    users_count: 2000,
    total_sold_honey_kg: 16727,
    total_bees: 53454000,
    total_flowers: 33454000000,  
  });
  const items = [{ 
    image: "/2020/svg/api_3bee.svg", 
    label: "<p style='margin-bottom: 6px; font-size: 18px;'><strong>"+Math.round(KPI?.total_bees*14+counter) + "</strong></p> "+labels.PROTECTED_BEES, 
    popup: labels.PROTECTED_BEES_DESC,
  },{ 
    image: "/2020/svg/hive_3bee.svg", 
    label: "<p style='margin-bottom: 6px; font-size: 18px;'><strong>"+Math.round(KPI?.users_count/35)+"</strong></p> " + labels.HIVE_MONITORED, 
    popup: labels.HIVE_MONITORED_DESC,
  },{ 
    image: "/2020/svg/flower_3bee.svg", 
    label: "<p style='margin-bottom: 6px; font-size: 18px;'><strong>"+Math.round(651) + "</strong></p> "+ labels.PARTNERS, 
    popup: labels.PARTNERS_DESC,
  },
 
  ];

  const boxKpi = [
    { 
      image: "/2020/svg/new/manomano.svg", 
      label: "<p style='margin-bottom: 6px; font-size: 18px;margin-top: 29px;'><strong>"+ "Oltre " + 100 + "</strong></p> "+ labels.BOX_ONBOARD, 
      popup: labels.BOX_ONBOARD_DESC,
    },
    { 
      image: "/2020/svg/new/cart.svg", 
      label: "<p style='margin-bottom: 6px; font-size: 18px; margin-top: 8px'><strong>"+ "Oltre " + 500 + "</strong></p> "+ labels.BOX_SOLD, 
      popup: labels.BOX_SOLD_DESC,
    },
  ];

  const { getKPI } = useApi();

  useEffect(() => {
    // update total price on cart changes
    getKPI().then((res) => {
      setKPI(res);
    });

  }, []);

  return (
    <StyledContainer>
      <CausesList title = '<span class="color-pink-background">Community</span> 3Bee' items = {!showBoxKpi ? items : [...items, ...boxKpi]}>
      </CausesList>
    </StyledContainer>
  );
};
